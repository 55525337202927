<template>
  <v-card color="#FCEC27" tile>
    <!-- <v-toolbar dark >
      <v-btn icon dark @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Login Here!</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider> -->
    <v-container fluid>
      <!-- <v-btn large icon @click.native="close" color="#02275D">
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
      <v-row class="mb-10 mt-2">
        <v-col
          cols="12"
          md="6"
          offset-md="3"
          sm="8"
          offset-sm="2"
          class="text-center"
        >
          <v-toolbar dark color="#02275D" class="mt-5 rounded-t-xl">
            <v-btn icon dark @click.native="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Register Here!</v-toolbar-title>
          </v-toolbar>
          <v-card
            elevation="5"
            tile
            class="pa-10 rounded-b-xl"
            dark
            color="#02275D"
          >
            <p align="center">
              <!-- <v-img src="/img/logo/logopajak.png" style="width: 100px"></v-img> -->
              <v-img src="/img/logo/lalampa-auth.png" style="width: 180px"></v-img>
            </p>
            <!-- <h2>{{ appNameNormal }}</h2>
            <small> Layanan Perlengkapan dan Manajemen Pengadaan ATK </small> -->
            <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
              <v-text-field
                v-model="nip"
                :rules="rules"
                label="NIP"
                type="number"
                required
                append-icon="mdi-account-key"
              ></v-text-field>
              <v-text-field
                v-model="full_name"
                :rules="rules"
                label="Nama Lengkap"
                required
                append-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                :rules="rules"
                label="Nomor Handphone"
                required
                append-icon="mdi-cellphone"
              ></v-text-field>
              <v-select
                v-model="position_id"
                :items="position"
                :rules="rules"
                dense
                item-value="id"
                item-text="position_name"
                label="Jabatan"
              ></v-select>
              <v-select
                v-model="division_id"
                :items="division"
                :rules="rules"
                dense
                item-value="id"
                item-text="division_name"
                label="Bidang"
                v-on:change="divisionChange"
              ></v-select>
              <v-select
                v-model="section_id"
                :items="section"
                :rules="rules"
                dense
                item-value="id"
                item-text="section_name"
                label="Seksi"
              ></v-select>
              <v-text-field
                v-model="grade"
                :rules="rules"
                label="Pangkat/Golongan"
                required
                append-icon="mdi-seal"
              ></v-text-field>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                append-icon="mdi-email"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                counter
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                v-model="confpassword"
                :append-icon="showconfPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="confpasswordRules.concat(passwordConfirmationRule)"
                :type="showconfPassword ? 'text' : 'password'"
                label="Konfirmasi Password"
                counter
                @click:append="showconfPassword = !showconfPassword"
              ></v-text-field>
              Upload Foto Terbaikmu! (Max. 1 MB)
              <input
                type="file"
                id="file"
                ref="file"
                accept="image/*"
                v-on:change="handleFileUpload()"
                style="color: orange;"
              />
              <br />
              <br />
              <div class="text-xs-center">
                <v-btn
                  color="#FCEC27"
                  light
                  :disabled="!valid"
                  @click="submit"
                  :loading="loading"
                >
                  Register
                  <v-icon right dark>mdi-account-plus</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      valid: true,
      loading: false,
      nip: "",
      full_name: "",
      phone: "",
      position_id: null,
      division_id: null,
      section_id: null,
      grade: "",
      email: "",
      showPassword: false,
      password: "",
      showconfPassword: false,
      confpassword: "",

      position: [],
      division: [],
      section: [],

      // Rules Form
      rules: [(v) => !!v || "Form ini wajib di isi"],
      passwordRules: [
        (v) => !!v || "Password wajib di isi",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
      confpasswordRules: [
        (v) => !!v || "Konfirmasi Password wajib di isi",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
      emailRules: [
        (v) => !!v || "Form ini wajib di isi",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),

    initialize() {
      this.getPosition();
      this.getDivision();
    },

    getPosition() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/positions", config)
        .then((response) => {
          // console.log(response);
          this.position = response.data;
          // console.log(response.data.data);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getDivision() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/divisions", config)
        .then((response) => {
          // console.log(response);
          this.division = response.data;
          // console.log(response.data.data);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    divisionChange: function (e) {
      // console.log(e);
      this.getSection(e);
    },

    getSection(item) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/sections", config)
        .then((response) => {
          this.$vloading.hide();

          var Data = response.data;
          var newData = Data.filter((e) => e.division_id == item);
          this.section = newData;
          // console.log(newData);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.productname = [];
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$vloading.show();
        const config = {
          headers: {
            Authorization: "Basic " + btoa("lalampa_app" + ":" + "lalampa@123"),
          },
        };
        let formData = new FormData();
        formData.set("nip", this.nip);
        formData.set("full_name", this.full_name);
        formData.set("phone", this.phone);
        formData.set("position_id", this.position_id);
        formData.set("division_id", this.division_id);
        formData.set("section_id", this.section_id);
        formData.set("grade", this.grade);
        formData.set("password", this.password);
        formData.set("password_confirm", this.password);
        formData.set("email", this.email);
        formData.set("photo", this.file);
        this.axios
          .post("/auth/register", formData, config)
          .then((response) => {
            this.loading = false;
            this.$vloading.hide();
            let { data } = response;
            console.log(data);
            // this.setAuth(data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Selamat!! Akun anda berhasil dibuat. Silakan Melakukan Login!",
            });
            this.close();
          })
          .catch((error) => {
            this.loading = false;
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
    },
    close() {
      this.$emit("closed", false);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>