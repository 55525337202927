<template>
  <v-card color="#FCEC27" tile>
    <!-- <v-toolbar dark >
      <v-btn icon dark @click.native="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Login Here!</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider> -->
    <v-container fluid>
      <!-- <v-btn large icon @click.native="close" color="#02275D">
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
      <v-row class="mt-8">
        <v-col
          cols="12"
          md="6"
          offset-md="3"
          sm="8"
          offset-sm="2"
          class="text-center"
        >
          <v-toolbar dark color="#02275D" class="mt-5 rounded-t-xl">
            <v-btn icon dark @click.native="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Login Here!</v-toolbar-title>
          </v-toolbar>
          <v-card
            elevation="5"
            tile
            class="pa-10 rounded-b-xl"
            dark
            color="#02275D"
          >
            <p align="center">
              <!-- <v-img src="/img/logo/logopajak.png" style="width: 100px"></v-img> -->
              <v-img src="/img/logo/lalampa-auth.png" style="width: 180px"></v-img>
            </p>
            <!-- <h2>{{ appNameNormal }}</h2>
            <small> Layanan Perlengkapan dan Manajemen Pengadaan ATK </small> -->
            <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
              <!-- <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                append-icon="mdi-email"
              ></v-text-field> -->
              <v-text-field
                v-model="nip"
                :rules="rules"
                label="NIP"
                type="number"
                required
                append-icon="mdi-account-key"
              ></v-text-field>
              <v-text-field
                v-model="passwordform"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                counter
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <div class="text-xs-center">
                <v-btn
                  color="#FCEC27"
                  light
                  :disabled="!valid"
                  @click="submit"
                  :loading="loading"
                >
                  Login
                  <v-icon right dark>mdi-lock-open</v-icon>
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      valid: true,
      loading: false,
      showPassword: false,
      // email: "198503302003121002",
      nip: "",
      passwordform: "",

      // Rules Form
      rules: [(v) => !!v || "Form ini wajib di isi"],
      passwordRules: [
        (v) => !!v || "Password required.",
        (v) => (v && v.length >= 8) || "Minimal 8 karakter",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$vloading.show();
        const config = {
          headers: {
            Authorization: "Basic " + btoa("lalampa_app" + ":" + "lalampa@123"),
          },
        };
        let formData = {
          username: this.nip,
          password: this.passwordform,
          grant_type: "password",
        };
        this.axios
          .post("/auth/login", formData, config)
          .then((response) => {
            this.loading = false;
            this.$vloading.hide();
            let { data } = response;
            // console.log(data);
            this.setAuth(data);
            this.setAlert({
              status: true,
              color: "success",
              text: "Login success",
            });
            this.close();
            // if (this.user.id > 0) {
            //   this.setAlert({
            //     status: true,
            //     color: "success",
            //     text: "Login success",
            //   });
            //   this.close();
            // } else {
            //   this.setAlert({
            //     status: true,
            //     color: "error",
            //     text: "Login failed",
            //   });
            // }
          })
          .catch((error) => {
            this.loading = false;
            this.$vloading.hide();
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
    },
    close() {
      this.$emit("closed", false);
      this.$router.replace({ name: "Home" });
    },
  },
};
</script>